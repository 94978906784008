import React from 'react'
import PropTypes from 'prop-types'
import './MenuItem.css'
import withScrollTo from '../../enhancers/withScrollTo'

const MenuItem = ({ route, scrollTo }) => {
  return (
    <a className="menu-item" href="" onClick={scrollTo}>
      {route.label}
    </a>
  )
}

MenuItem.propTypes = {
  route: PropTypes.shape({
    label: PropTypes.string.isRequired,
    scrollTo: PropTypes.string.isRequired
  }),
  scrollTo: PropTypes.func
}

export default withScrollTo(MenuItem)
