import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Main from './screens/Main'

const router = (
  <Router>
    <div className="container">
      <Route exact path="/" component={Main} />
    </div>
  </Router>
)

export default router
