import React from 'react'
import Carousel from 'nuka-carousel'
import Box from '../box/Box'
import './CarouselBox.css'
import { Photos } from './photos'
import chevronLeft from '../../assets/iconChevronLeft.svg'
import chevronRight from '../../assets/iconChevronRight.svg'

const CarouselBox = () => {
  const renderPrevSlide = ({ previousSlide }) => {
    return (
      <button className="slider-button" onClick={previousSlide}>
        <img src={chevronLeft} alt="" />
      </button>
    )
  }

  const renderNextSlide = ({ nextSlide }) => {
    return (
      <button className="slider-button" onClick={nextSlide}>
        <img src={chevronRight} alt="" />
      </button>
    )
  }
  const getCarousel = () => {
    return (
      <Carousel
        autoplay
        style={{ height: '100%' }}
        wrapAround
        renderCenterLeftControls={renderPrevSlide}
        renderCenterRightControls={renderNextSlide}
        renderBottomCenterControls={() => null}
      >
        {Photos.map((photo, idx) => {
          return <img key={idx} src={photo} alt="" />
        })}
      </Carousel>
    )
  }
  return <Box content={getCarousel()} />
}

export default CarouselBox
