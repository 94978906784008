import React from 'react'
import './Menu.css'
import routes from '../../routes'
import MenuItem from '../menuItem/MenuItem'
import logoS from '../../assets/logo-s.png'

const Menu = () => {
  return (
    <header className="menu">
      <div className="content">
        <div className="logo-wrapper">
          <img className="logo" alt="Teto Pelo Joven Logo" src={logoS} />
        </div>

        <nav>
          {routes.map(route => {
            return <MenuItem key={route.label} route={route} />
          })}
        </nav>
      </div>
    </header>
  )
}

export default Menu
