import React from 'react'
import PropTypes from 'prop-types'
import scroll from '../helpers/Scroll'

const MENU_OFFSET = 120

const withScrollTo = WrappedComponent => {
  const ScrollTo = ({ route, ...rest }) => {
    const onScrollTo = evt => {
      evt.preventDefault()
      const { scrollTo } = route
      const node = document.querySelector(scrollTo)
      if (!node) {
        throw new Error(`Node not found for ${scrollTo}`)
      }

      scroll(node.offsetTop - MENU_OFFSET, 300)
    }

    return <WrappedComponent {...rest} route={route} scrollTo={onScrollTo} />
  }

  ScrollTo.propTypes = {
    route: PropTypes.shape({
      label: PropTypes.string.isRequired,
      scrollTo: PropTypes.string.isRequired
    })
  }

  return ScrollTo
}

export default withScrollTo
