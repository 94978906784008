import React from 'react'
import PropTypes from 'prop-types'
import Box from '../box/Box'
import './ImageBox.css'

const ImageBox = ({ src }) => {
  return <Box content={<img className="img-box" src={src} alt="" />} />
}

ImageBox.propTypes = {
  src: PropTypes.string.isRequired
}

export default ImageBox
