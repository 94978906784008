import photo1 from '../../assets/slider/1.jpg'
import photo2 from '../../assets/slider/2.jpg'
import photo3 from '../../assets/slider/3.jpg'
import photo4 from '../../assets/slider/4.jpg'
import photo5 from '../../assets/slider/5.jpg'
import photo6 from '../../assets/slider/6.jpg'
import photo7 from '../../assets/slider/7.jpg'
import photo8 from '../../assets/slider/8.jpg'
import photo9 from '../../assets/slider/9.jpg'
import photo10 from '../../assets/slider/10.jpg'
import photo11 from '../../assets/slider/11.jpg'
import photo12 from '../../assets/slider/12.jpg'
import photo13 from '../../assets/slider/13.jpg'
import photo14 from '../../assets/slider/14.jpg'
import photo15 from '../../assets/slider/15.jpg'
import photo16 from '../../assets/slider/16.jpg'
import photo17 from '../../assets/slider/17.jpg'

export const Photos = [
  photo1,
  photo2,
  photo3,
  photo4,
  photo5,
  photo6,
  photo7,
  photo8,
  photo9,
  photo10,
  photo11,
  photo12,
  photo13,
  photo14,
  photo15,
  photo16,
  photo17
]
