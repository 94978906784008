import React from 'react'
import Section from '../../components/section/Section'
import SectionHeader from '../../components/sectionHeader/SectionHeader'
import TeamFile from '../../components/teamFile/TeamFile'
import './TeamSection.css'
import david from '../../assets/team-david.png'
import nati from '../../assets/team-nati.png'
import Content from '../../components/content/Content'

const members = [
  {
    image: david,
    name: 'David',
    description: 'Peluquero de toda la vida, entusiasta y carismático.'
  },
  {
    image: nati,
    name: 'Nati',
    description: '¡No hay peinado que se le resista!'
  }
]

const TeamSection = () => {
  return (
    <Section id="teto-ts" className="team-section">
      <Content>
        <SectionHeader text={'Nosotros'} />
        <div className="team-members">
          {members.map(member => {
            return <TeamFile {...member} key={member.name} />
          })}
        </div>
      </Content>
    </Section>
  )
}

export default TeamSection
