import React from 'react'
import './SectionHeader.css'
import SectionHeaderDecoration from '../sectionHeaderDecoration/SectionHeaderDecoration'

const SectionHeader = ({ text }) => {
  return (
    <div className="section-header">
      <h2>{text}</h2>

      <SectionHeaderDecoration />
    </div>
  )
}

export default SectionHeader
