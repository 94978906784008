import React, { Fragment } from 'react'

const LegalContent = () => {
  return (
    <Fragment>
      <p>
        Las presentes Condiciones Generales regulan el acceso a y el uso del
        sitio web propiedad de Teto Pelo Joven
      </p>
      <p>
        1. Toda persona que acceda al Sitio Web, www.tetopelojoven.com, deberá
        leer las Condiciones Generales vigentes en el momento de su acceso,
        debiendo abstenerse de utilizar el Sitio Web en caso de no estar
        plenamente conforme con las presentes Condiciones Generales. La mera
        utilización del Sitio Web atribuye la condición de usuario ("Usuario") y
        supone la plena aceptación de y adhesión sin reservas a estas
        Condiciones Generales.&nbsp;
      </p>
      <p>
        2. No podrán ser Usuarios del Sitio Web los menores de edad que no
        cuenten con el correspondiente permiso de sus padres o de quienes tengan
        encomendada su representación legal. Teto Pelo Joven asume que desde el
        momento en que un menor de edad accede al Sitio Web cuenta con el
        preceptivo permiso de sus padres o representantes legales, que deberán
        conocer y aceptar las Condiciones Generales vigentes en el momento en
        que el menor a su cargo acceda al Sitio Web.&nbsp;
      </p>
      <p>
        3. Teto Pelo Joven se reserva el derecho de modificar, en cualquier
        momento y sin necesidad de previo aviso, la presentación y configuración
        del Sitio Web, así como las presentes Condiciones Generales. En
        consecuencia, cada vez que acceda al Sitio Web, el Usuario deberá
        consultar y aceptar la última versión disponible de estas Condiciones
        Generales antes de continuar utilizando el Sitio Web.&nbsp;
      </p>
      <p>4. Uso del sitio web</p>
      <p>
        Sin perjuicio de los costes de acceso y de conexión a Internet, así como
        de los que se deriven de la adquisición de licencias de uso de
        aplicaciones informáticas necesarias para utilizar el Sitio Web, todos
        los cuales serán íntegramente asumidos por el Usuario, el uso del Sitio
        Web tendrá carácter gratuito.&nbsp;
      </p>
      <p>
        El Usuario es consciente y acepta voluntaria y expresamente que el uso
        del Sitio Web se realiza en todo caso bajo su única y exclusiva
        responsabilidad. En la utilización del Sitio Web, el Usuario se
        compromete a no falsear su identidad, a utilizar el Sitio Web
        exclusivamente para fines particulares y no como parte de una actividad
        empresarial, a no llevar a cabo ninguna conducta que pueda dañar la
        imagen, los intereses y los derechos de Teto Pelo Joven o de terceros ni
        que sea contraria a la Ley, la moral o el orden público o pueda dañar,
        inutilizar, sobrecargar o impedir, de cualquier forma, la normal
        utilización del Sitio Web.&nbsp;
      </p>
      <p>
        Teto Pelo Joven adopta medidas de seguridad razonablemente adecuadas
        para detectar la existencia de virus. No obstante, el Usuario es
        consciente y acepta que las medidas de seguridad disponibles para los
        sistemas informáticos en Internet no son enteramente fiables y que, por
        tanto, Teto Pelo Joven no puede garantizar la inexistencia de virus u
        otros elementos que puedan producir alteraciones en los sistemas
        informáticos (software y hardware) del Usuario o en sus documentos
        electrónicos y ficheros contenidos en los anteriores.&nbsp;
      </p>
      <p>5. Contenidos del sitio web</p>
      <p>
        Toda la información contenida en la web proviene de fuentes consideradas
        fiables. No obstante, la exactitud de estos datos no queda garantizada.
        La información facilitada es únicamente orientativa y puede ser revisada
        sin notificación previa en cualquier momento. Por lo tanto, Teto Pelo
        Joven no se hace responsable de errores y omisiones de información que,
        en todo caso, intentarán subsanarse con la mayor prontitud
        posible.&nbsp;
      </p>
      <p>
        6. El usuario única y exclusivamente puede utilizar el material que
        aparezca en este portal para su uso personal y privado, quedando
        prohibido su uso con fines comerciales o para incurrir en actividades
        ilícitas. Todos los derechos derivados de la propiedad intelectual están
        expresamente reservados por Teto Pelo Joven.&nbsp;
      </p>
    </Fragment>
  )
}

export default LegalContent
