import React from 'react'
import PropTypes from 'prop-types'
import './Modal.css'

const Modal = ({ title, children }) => {
  return (
    <div className="modal">
      <div className="modal-header">
        <h2>{title}</h2>
      </div>

      <div className="modal-content">{children}</div>
    </div>
  )
}

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default Modal
