import React from 'react'
import './InfoSection.css'
import Section from '../../components/section/Section'
import ImageBox from '../../components/imageBox/ImageBox'
import TextBox from '../../components/textBox/TextBox'

import barberShop from '../../assets/barber-shop.png'
import barberRazor from '../../assets/barber-razor.png'
import phoneIcon from '../../assets/phone.svg'
import fbIcon from '../../assets/facebook.svg'
import CarouselBox from '../../components/carouselBox/CarouselBox'
import Content from '../../components/content/Content'

const InfoSection = () => {
  return (
    <Section id="teto-is" className="info-section">
      <Content>
        <div className="grid">
          <div className="grid-box1">
            <div className="grid-content">
              <ImageBox src={barberShop} />
            </div>
          </div>
          <div className="grid-box2">
            <div className="grid-content">
              <TextBox>
                <h3 className="box-header">Horario</h3>
                <p className="box-text">Lunes - Viernes</p>
                <b className="box-timetable">9:00h - 21:00h</b>

                <p className="box-text">Sábados</p>
                <b className="box-timetable">9:00h - 14:00h</b>
              </TextBox>
            </div>
          </div>
          <div className="grid-box3">
            <div className="grid-content">
              <ImageBox src={barberRazor} />
            </div>
          </div>
          <div className="grid-box4">
            <div className="grid-content">
              <TextBox className="box-phone-number">
                <h3 className="box-header">¡Pide cita!</h3>
                <img className="box-phone-icon" alt="" src={phoneIcon} />
                <b className="box-phone">675 79 20 92</b>
              </TextBox>
            </div>
          </div>
          <div className="grid-box5">
            <div className="grid-content">
              <iframe
                title="Map"
                width="100%"
                height="100%"
                frameBorder="0"
                style={{ border: 0 }}
                src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJO-r2uNJoEg0Rc_Vngl7iwNY&key=AIzaSyBpXo-yhp-O47W1sRDohvUYzu2ZQm1F0Yw"
                allowFullScreen
              />
            </div>
          </div>
          <div className="grid-box6">
            <div className="grid-content">
              <TextBox className="box-facebook">
                <h3 className="box-header">Síguenos</h3>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/teto.pelojoven?fref=ts"
                >
                  <img className="box-fb-icon" alt="" src={fbIcon} />
                </a>
              </TextBox>
            </div>
          </div>
          <div className="slider-box">
            <CarouselBox />
          </div>
        </div>
      </Content>
    </Section>
  )
}

export default InfoSection
