import React from 'react'
import './SectionHeaderDecoration.css'
import scissors from '../../assets/scissors.svg'

const SectionHeaderDecoration = () => {
  return (
    <div className="section-header-decoration">
      <img alt="" src={scissors} />
    </div>
  )
}

export default SectionHeaderDecoration
