import React from 'react'
import Section from '../../components/section/Section'
import './WelcomeSection.css'
import SectionHeader from '../../components/sectionHeader/SectionHeader'
import Content from '../../components/content/Content'

const WelcomeSection = () => {
  return (
    <Section id="teto-ws" className="welcome-section">
      <Content>
        <SectionHeader text={'Bienvenido a Teto Pelo Joven'} />

        <p>
          Con más de 22 años de experiencia, Teto Pelo Joven, es una de las{' '}
          <b>mejores referencias de Sevilla en peluquería masculina</b>. Desde
          la barbería más primitiva hasta los cortes más vanguardistas,
          ofrecemos un <b>servicio de calidad y atención personalizada</b>.
          Nuestro grupo de estilistas altamente cualificados, te asesorarán y
          estilizarán tu peinado.
        </p>

        <p>
          Tenemos a la disposición del cliente una serie de tratamientos de
          alisado etc. Además, todos los productos que utilizamos pueden ser
          adquiridos en nuestra peluquería a un precio más que asequible.
        </p>
      </Content>
    </Section>
  )
}

export default WelcomeSection
