import React from 'react'
import PropTypes from 'prop-types'
import Box from '../box/Box'
import './TextBox.css'
import c from 'classnames'

const TextBox = ({ children, className }) => {
  return (
    <Box content={<div className={c('text-box', className)}>{children}</div>} />
  )
}

TextBox.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default TextBox
