import React from 'react'
import PropTypes from 'prop-types'
import './TeamFile.css'

const TeamFile = ({ image, name, description }) => {
  return (
    <div className="team-file-wrapper">
      <img src={image} alt="" className="team-file-profile" />
      <h2 className="team-file-name">{name}</h2>
      <p className="team-file-description">{description}</p>
    </div>
  )
}

TeamFile.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
}

export default TeamFile
