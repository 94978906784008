import React from 'react'
import './Footer.css'
import Content from '../content/Content'
import PropTypes from 'prop-types'

const Footer = ({ openLegal }) => {
  return (
    <footer>
      <Content>
        <div className="footer-actions-wrapper">
          <a
            href=""
            onClick={e => {
              e.preventDefault()
              openLegal()
            }}
            className="footer-action"
          >
            Aviso legal
          </a>
        </div>
      </Content>
    </footer>
  )
}

Footer.propTypes = {
  openLegal: PropTypes.func.isRequired
}

export default Footer
