export default [
  {
    scrollTo: '#teto-ws',
    label: 'Inicio'
  },
  {
    scrollTo: '#teto-is',
    label: 'Nuestra peluquería'
  },
  {
    scrollTo: '#teto-ts',
    label: 'Nosotros'
  },
  {
    scrollTo: '#teto-ps',
    label: 'Precios'
  }
]
