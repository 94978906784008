import React from 'react'
import PropTypes from 'prop-types'
import './Section.css'

const Section = ({ id, className, children }) => {
  return (
    <section id={id} className={className}>
      {children}
    </section>
  )
}

Section.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default Section
