import React from 'react'
import Section from '../../components/section/Section'
import './PricingSection.css'
import Content from '../../components/content/Content'
import SectionHeader from '../../components/sectionHeader/SectionHeader'
import barberPricing from '../../assets/barber-pricing.png'
import PriceItem from '../../components/priceItem/PriceItem'

const prices = [
  {
    label: 'Corte caballero',
    price: 11
  },
  {
    label: 'Corte jubilado',
    price: 6
  },
  {
    label: 'Corte niño',
    price: 8
  }
]

const PricingSection = () => {
  return (
    <Section id="teto-ps" className="pricing-section">
      <Content>
        <SectionHeader text="Precios" />
        <div className="pricing-wrapper">
          <img alt="" src={barberPricing} />
          <div className="pricing-list">
            {prices.map((price, idx) => {
              return <PriceItem {...price} key={idx} />
            })}
          </div>
        </div>
      </Content>
    </Section>
  )
}

export default PricingSection
