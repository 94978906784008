import React from 'react'
import PropTypes from 'prop-types'
import './Box.css'

const Box = ({ content }) => {
  return <div className="box">{content}</div>
}

Box.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.arrayOf([PropTypes.node])
  ])
}

export default Box
