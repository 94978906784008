import React from 'react'
import PropTypes from 'prop-types'
import './Content.css'

const Content = ({ children }) => {
  return <div className="content">{children}</div>
}

Content.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default Content
