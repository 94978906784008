import React from 'react'
import Section from '../../components/section/Section'
import logoXL from '../../assets/logo-xl.png'
import './MainSection.css'
import Content from '../../components/content/Content'

const MainSection = () => {
  return (
    <Section id="teto-ms" className="main-section">
      <Content>
        <img src={logoXL} alt="" />
      </Content>
    </Section>
  )
}

export default MainSection
