import React, { Fragment, Component } from 'react'
import Menu from '../components/menu/Menu'
import './Main.css'
import MainSection from '../sections/main/MainSection'
import WelcomeSection from '../sections/welcome/WelcomeSection'
import InfoSection from '../sections/info/InfoSection'
import TeamSection from '../sections/team/TeamSection'
import PricingSection from '../sections/pricing/PricingSection'
import Footer from '../components/footer/Footer'
// include styles
import 'rodal/lib/rodal.css'
import Rodal from 'rodal'
import Modal from '../components/modal/Modal'
import LegalContent from '../components/legalContent/LegalContent'

class Main extends Component {
  state = {
    legalOpened: false
  }

  openLegal = () => {
    this.setState({ legalOpened: true })
  }

  closeLegal = () => {
    this.setState({ legalOpened: false })
  }

  render() {
    const { legalOpened } = this.state
    return (
      <Fragment>
        <Menu />
        <MainSection />
        <div className="main-content">
          <WelcomeSection />
          <InfoSection />
          <TeamSection />
          <PricingSection />
        </div>
        <Footer openLegal={this.openLegal} />

        <Rodal
          className="pop-up"
          width={80}
          height={60}
          measure={'%'}
          visible={legalOpened}
          onClose={this.closeLegal}
          closeOnEsc
          closeMaskOnClick
          animation={'slideDown'}
        >
          <Modal title={'Aviso legal'}>
            <LegalContent />
          </Modal>
        </Rodal>
      </Fragment>
    )
  }
}

export default Main
