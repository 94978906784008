import React from 'react'
import PropTypes from 'prop-types'
import './PriceItem.css'

const PriceItem = ({ label, price }) => {
  return (
    <p className="price-item-wrapper">
      <span className="price-item-label">{label}</span>
      <span className="price-item-amount">{price}</span>
    </p>
  )
}

PriceItem.propTypes = {
  label: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired
}

export default PriceItem
